<template>
<div>
  <ServerError v-if="ServerError" />
   <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="blue"
      spinner="mini-spinner"
    />
  <v-layout wrap justify-center>
   
    <v-flex xs12 lg8 mt-6>
        <v-layout  wrap justify-space-between>
                    <v-flex xs12 lg4>
                      <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        :return-value.sync="fromDate"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="fromDate"
                            label="From"
                            outlined
                            readonly
                            dense
                           
                            clearable
                            class="nsregular pr-2 rounded-0"
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="fromDate" no-title scrollable>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menu = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.menu.save(fromDate)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-flex>
                    <v-flex xs12 lg4>
                      <v-menu
                        ref="menu2"
                        v-model="menu2"
                        :close-on-content-click="false"
                        :return-value.sync="toDate"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="toDate"
                            label="To"
                            readonly
                            outlined
                            dense
                            clearable
                            class="nsregular px-2 rounded-0"
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="toDate" no-title scrollable>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menu2 = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.menu2.save(toDate)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-flex>
                    <v-flex xs12 lg4>
                        <v-text-field
                        outlined
                        label="Search"
                        dense
                       clearable
                        class="nsregular px-2  rounded-0"
                        v-model="keyword"
                      ></v-text-field>
                    </v-flex>

                  </v-layout>
    </v-flex>
    <v-flex xs12 lg8>
      <v-card elevation="2">
        <v-tabs v-model="tab" align-with-title>
          <v-tabs-slider color="#766BC0"></v-tabs-slider>

          <v-tab
            class="nsbold"
            style="font-size: 16px"
            v-for="item in statusItems"
            :key="item"
            @click="getCurrent(item)"
          >
            {{ item }}
          </v-tab>
        </v-tabs>
        <!-- <v-tab-item>
         
      </v-tab-item> -->
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="item in statusItems" :key="item">
            <v-card flat>
              <v-layout wrap justify-center>
                <v-flex xs12 v-if="mylist" px-6 pt-2>
                  

                  <v-card round style="background-color: #eeeeee">
                    <v-layout wrap pa-2 class="nsbold" style="font-size: 16px">
                      <v-flex xs2>QR Id</v-flex>
                      <v-flex xs2>Name</v-flex>
                      <v-flex xs2>Ad.No</v-flex>
                      <!-- <v-flex xs1>Fabric</v-flex> -->
                      <!-- <v-flex xs2>Preferences</v-flex> -->
                      <v-flex xs2>Type</v-flex>
                      <!-- <v-flex xs2>Remark</v-flex> -->
                      <v-flex xs2>Date</v-flex>
                    </v-layout>
                  </v-card>
                  <v-card v-for="(item, i) in mylist" :key="i" elevation="0">
                    <v-layout
                      wrap
                      pa-2
                      class="nsregular"
                      style="font-size: 16px"
                    >
                      <v-flex xs2>{{ item.clothid.clothbarid }}</v-flex>
                      <v-flex xs2
                        >{{ item.studentid.name }}</v-flex
                      >
                      <v-flex xs2>{{ item.studentid.admissionNo }}</v-flex>
                      <!-- <v-flex xs1>{{ item.clothid.clothType }}</v-flex> -->
                      <!-- <v-flex xs2>{{ item.clothid.preferences }}</v-flex> -->
                      <v-flex xs2>{{ item.clothid.clothType.name }}</v-flex>
                      <!-- <v-flex xs2>{{ item.clothid.Remark }}</v-flex> -->
                      <v-flex xs4>{{ formatDate(item.update_date) }}</v-flex>

                      <!-- <v-flex xs1>
                    <v-select
                      :items="statusItems"
                      v-model="status"
                      label="status"
                      outlined
                      dense
                    ></v-select>
                    </v-flex> -->
                    </v-layout>
                    <v-divider></v-divider>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
        <v-layout wrap pt-3>
          <v-flex xs12 text-center>
            <v-pagination small v-model="page" :length="test"> </v-pagination>
          </v-flex>
        </v-layout>
      </v-card>
    </v-flex>
  </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      menu: false,
      menu2: false,
      ServerError: false,
      fromDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      toDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
        keyword:null,
      curUrl: "/view/clothstatus",
      test: 0,
      page: 1,
      Pagelength: 0,
      appLoading: false,
      a: "getlist/status/in",
      b: "getlist/history/status/out",
      //pagination

      limit: 20,

      // pages: 0,
      // pageCount: 0,
      // currentPage: 1,

      user: [],
      tab: null,
      statusItems: null,
      mylist: [],
      items: ["web", "shopping", "videos", "images", "news"],
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    };
  },
  mounted() {
    this.getData();
  },
  watch: {
    page() {
      // this.getData();
       this.getCurrent(this.tab);
    },
    fromDate(){
        // this.getData();
        this.getCurrent(this.tab);
    },
    toDate(){
        // this.getData();
        this.getCurrent(this.tab);
    },
    keyword(){
        // this.getData();
        this.getCurrent(this.tab);
    }
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "get",
        url: "/view/clothstatus",

        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          id: this.$route.query.id,
          page: this.page,
          limit: 20,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.user = response.data.data;
            this.statusItems = response.data.data;
            this.Pagelength = Math.ceil(response.data.totalLength / 20);
            
            if (this.statusItems.length > 0) {
              this.getCurrent(this.statusItems[0]);
            }
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },

    getCurrent(status) {
      this.appLoading=true;
      console.log("status", status);
      this.mylist = null;
      if (status == "IN") {
        Url = this.a;
      } else {
        Url = this.b;
      }

      //  let a = "getlist/status/in";

      let Url;
      axios({
        method: "POST",
        // datas: {

        // },
        url: Url,
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
        keyword: this.keyword,
        fromDate: this.fromDate,
        toDate: this.toDate,
          presentStatus: status,
          page: this.page,
          limit: 20,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.mylist = response.data.data;
            this.pagelength = Math.ceil(response.data.totalLength / 20);
            this.test = Math.ceil(response.data.totalLength / 20);
          }
        })
        .catch((err) => {
          // this.appLoading = false;
          console.log(err);
          this.ServerError = true;
        });
    },

    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year +
        " , " +
        hours +
        ":" +
        minutes +
        " " +
        ampm;

      return strTime;
    },
  },
};
</script>